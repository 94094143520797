import './Customer.css';
import { Link } from 'react-router-dom';

function Customer({ item, setShowForm, setCustomer, type = "Lonca" }) {

  if(type === "Logistics") {
    return (
      <div className="list-customer">
        <Link 
          className="list-customer-item" 
          to={`../logistics-customers/${item?._id}`}
        >
          <div className="list-customer-item-check"></div>
          <div className="list-customer-item-first">{item.firstName}</div>
          <div className="list-customer-item-last">{item.lastName}</div>
          <div className="list-customer-item-shipment">{item?.unique_customer_id}</div>
          <div className="list-customer-item-phone">{item.phone_number}</div>
          <div className="list-customer-item-email">{item.email}</div>
        </Link>
      </div>
    );
  }
  
  return (
    <div className="list-customer">
      <Link 
        className="list-customer-item" 
        to={`../customers/${item?._id}`}
      >
        <div className="list-customer-item-check"></div>
        <div className="list-customer-item-first">{item.firstName}</div>
        <div className="list-customer-item-last">{item.lastName}</div>
        <div className="list-customer-item-email">{item.email}</div>
        <div className="list-customer-item-phone">{item.phone_number}</div>
        <div className="list-customer-item-shipment">{item?.shipment_country?.name}</div>
      </Link>
    </div>
  );
}

export default Customer;
