import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchCreate = (order, needCalculatePrices = false) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/logistics-order/create`, { order, needCalculatePrices });
}

export const listOrders = async ({ filter, mode, cancelToken = null }) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/logistics-order/list-for-admin?mode=${mode}`, { filter }, { cancelToken: cancelToken });
}

export const fetchUpdate = async (id, update, needCalculatePrices = false) => {
  const url = getUrlFromState(store)

  const { data } = await axios.patch(`${url}/logistics-order/one/${id}`, { update, needCalculatePrices });
  return data;
}

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(`${url}/logistics-order/one/${id}`);
}

export const fetchCreateEnglishInvoice = ({ order }) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/logistics-order/create-english-invoice`, { order });
}

export const createShipment = async (orderId) => {
  const url = getUrlFromState(store)
  const { data } = await axios.post(`${url}/logistics-order/create-shipment`, { orderId });
  return data;
}

