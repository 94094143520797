import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { getUrlFromState } from './userSlice';
import store from './store'

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ query, signal = null }, { rejectWithValue }) => {
    try {
      const mode = 'query_list';
      const url = getUrlFromState(store);
      const { data } = await axios.post(`${url}/product_parent`, { query, mode }, { signal });

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const fetchAllProductsHandle = createAsyncThunk(
  'products/fetchAllProductsHandle',
  async () => {
    const mode = 'all_list'
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/product_parent`, { query: {}, mode });

    return data;
  },
);

export const fetchAllSearchResults = createAsyncThunk(
  'products/fetchAllSearchResults',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/product_parent/search-all-results`, filter);
    return data;
  },
);

export const fetchProduct = (id) => {
  const url = getUrlFromState()
  return axios.get(`${url}/product_parent/${id}`);
}

export const fetchProductByHandleOrId = ({handle, region, mode, id}) =>{
  const url = getUrlFromState(store)
  return axios.post(`${url}/product_parent/handle_admin/`, {region, mode, id, handle});
}
export const fetchUpdateProduct = (
  id,
  changedFields,
  mainImage,
  imageLinks,
  chartImage,
  videoLinks
) => {
  const url = getUrlFromState(store)

  return axios.patch(
  `${url}/product_parent/one/${id}`,
  {
    changedFields,
    imageLinks,
    main_image: mainImage,
    chartImage,
    videoLinks
  },
)};

export const fetchCreateProduct = (fields, uploadedLinks, mainImage, chartImage, videoLinks, vendorLastProductCount) => {
  const url = getUrlFromState(store)
  return axios.post(
  `${url}/product_parent/create`,
  {
    fields,
    uploadedLinks,
    chartImage,
    main_image: mainImage,
    videoLinks,
    vendorLastProductCount
  },
)};

export const fetchDelete = (id) => {
  const url = getUrlFromState(store)
  return axios.delete(
  `${url}/product_parent/one/${id}`,
)};

export const fetchUploadUrl = (key, type) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/product_parent/s3-url-admin`, { key, type });
}

export const fetchNewSkuCode = () => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/product_parent/sku/`);
}

export const sendScrapers = async (query) => {
  const url = getUrlFromState(store)
  const data = await axios.post(`${url}/product_parent/delete-scraper`, query);
  return data;
};

export const fetchProductAggregate = ({ filter, mode = "", cancelToken = null }) => {
  const requestBody = {
    filter,
    mode,
  };
  const url = getUrlFromState(store)

  return axios.post(`${url}/product_parent/aggregate-products-for-admin`, requestBody, {
    cancelToken: cancelToken,
  });
};

export const updateTagProducts = ({ changedProducts, initialProducts, checkConflictOnly }) => {
  let log =changedProducts?.map((changedProduct) =>  {return {
    ids: changedProduct?._id,
    tagIds: changedProduct?.tags?.map(tag => tag?._id)
  }})

  const url = getUrlFromState(store);
  return axios.post(`${url}/product_parent/tag-products-update`, { changedProducts, initialProducts, checkConflictOnly, log });
};

export const updateProductDetails = ({ changedProducts, initialProducts, checkConflictOnly }) => {
  const url = getUrlFromState(store);
  return axios.post(`${url}/product_parent/product-details-update`, { changedProducts, initialProducts, checkConflictOnly });
};

export const exportProducts = (query) => {
  const url = getUrlFromState(store);
  return axios.post(`${url}/product_parent/product-export`, { query });
};

export const importProductsTemplate = (params) => {
  const url = getUrlFromState(store);
  return axios.get(`${url}/product_parent/product-import-template`, { params });
};

export const importProducts = ({ data, isEntry }) => {
  const url = getUrlFromState(store);
  return axios.post(
    `${url}/product_parent/product-import`, 
    { data, isEntry }
  );
};

export const fetchCsvDiscountedProducts = () => {
  const url = getUrlFromState(store);
  return axios.post(`${url}/product_parent/csv-discounted-products`);
};

export const removeCsvDiscountedProducts = (productIds) => {
  const url = getUrlFromState(store);
  return axios.post(`${url}/product_parent/remove-csv-discounted-products`, { productIds });
};

const productSlice = createSlice({
  name: 'product',
  initialState: {
    filter: {
      page: 1,
      limit: 100,
    },
    products: [],
    count : 0,
    allProductsHandles: [],
    allActiveProducts: [],
    status: {
      fetchProducts: 'idle',
      fetchAllSearchResults: 'idle',
      fetchAllProductsHandle: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    // normal filter results
    [fetchProducts.pending]: (state) => {
      state.status.fetchProducts = 'pending';
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.status.fetchProducts = 'succeeded';
      state.products = action.payload.products;
      state.count = action.payload.count;
    },
    [fetchProducts.rejected]: (state, action) => {
      state.status.fetchProducts = 'rejected';
      state.error = action.payload;
    },

    // all search results
    [fetchAllSearchResults.pending]: (state) => {
      state.status.fetchAllSearchResults = 'pending';
    },
    [fetchAllSearchResults.fulfilled]: (state, action) => {
      state.status.fetchAllSearchResults = 'succeeded';
      state.products = action.payload.products;
      state.count = action.payload.count;
    },
    [fetchAllSearchResults.rejected]: (state, action) => {
      state.status.fetchAllSearchResults = 'rejected';
      state.error = action.payload;
    },

    [fetchAllProductsHandle.pending]: (state) => {
      state.status.fetchAllProductsHandle = 'pending';
    },
    [fetchAllProductsHandle.fulfilled]: (state, action) => {
      state.status.fetchAllProductsHandle = 'succeeded';
      if(action.payload?.length > 0) {
        state.allProductsHandles = action.payload;
        state.allActiveProducts = action.payload?.filter(p => p?.status === "Active")
      }
    },
    [fetchAllProductsHandle.rejected]: (state, action) => {
      state.status.fetchAllProductsHandle = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = productSlice.actions;

export default productSlice.reducer;
