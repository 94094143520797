import axios from "axios";

import { getUrlFromState } from "./userSlice";
import store from "./store";

export const fetchCreate = (customer) => {
  const url = getUrlFromState(store)

  return axios.post(`${url}/logistics-customer/create`, {
    customer,
  });
};

export const fetchUpdate = (id, update) => {
  const url = getUrlFromState(store)

  return axios.patch(`${url}/logistics-customer/one/${id}`, {
    update,
  });
};

export const fetchCustomers = async (query) => {
  const url = getUrlFromState(store)
  return axios.post(`${url}/logistics-customer`, { query });
};

export const fetchCustomerById = (id) => {
  const url = getUrlFromState(store)
  return axios.get(`${url}/logistics-customer/one/${id}`);
};



