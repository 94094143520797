import React from 'react';
import { Form, Select } from 'antd';

const SubVendorSelector = ({ product, setProduct, setIsDirty, addNew = false, subVendors }) => {
  const handleChange = (e) => {
    setProduct({
      ...product,
      sub_vendor_name: e,
    });
    setIsDirty(true);
  };

  return (
    <Form.Item label="Sub Vendor Name">
      <Select
        showSearch
        optionFilterProp="children"
        onChange={handleChange}
        value={product?.sub_vendor_name}
        disabled={!addNew}
      >
        {subVendors?.map((subVendor) => subVendor?.name)
          ?.slice()
          .sort((a, b) => a.localeCompare(b))
          .map((vendor, index) => (
            <Select.Option key={'vendor-select' + index} value={vendor}>
              {vendor}
            </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SubVendorSelector;
