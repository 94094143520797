import React, { useEffect, useState } from 'react';
import { Form, Button, Checkbox, notification, DatePicker, Input } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import { t } from 'i18next';
import moment from 'moment';
import { fetchUnusedWarehouseBox, fetchWarehouseBoxes } from "../../redux/warehouseBoxSlice";
import { initStockBox } from '../../helpers';

const StockBoxEditorSubSeries = ({ product, setIsDirty, user, setProduct, changedStockBox, setChangedStockBox, subSeriesIndex }) => {
  const [stockBox, setStockBox] = useState([]);
  const [newWarehouseBox, setNewWarehouseBox] = useState(null);
  const [warehouseBoxes, setWarehouseBoxes] = useState(null);

  useEffect(() => {
    fetchWarehouseBoxes({ type: "stock", is_disabled: { $ne: true }, is_full: { $ne: true } })
      .then(res => {
        setWarehouseBoxes(res.data)
      })
  }, [])


  useEffect(() => {
    if (product?.sub_series[subSeriesIndex]?.stock_box?.length > 0) {
      const initialStockBoxState = product?.sub_series[subSeriesIndex]?.stock_box?.map((box) => ({ ...box, tempType: "OldBox" })); //temp value
      setStockBox(initialStockBoxState);

      handleStockChange(product?.sub_series[subSeriesIndex]?.stock_box, subSeriesIndex);

    }
    
  }, []);

  const handleFetchWarehouseBoxes = async() => {
    try {
      const res = await fetchUnusedWarehouseBox("stock");
      const firstWarehouseBox = res?.data;

      setNewWarehouseBox(firstWarehouseBox);
      return firstWarehouseBox;
    } catch (error) {
      console.error("An error occurred while fetching warehouse boxes:", error);
    }          
  }

  const handleAddStockBox = async() => {

    if(!product?.sub_series[subSeriesIndex]?.stock || product?.sub_series[subSeriesIndex]?.stock == 0) {
      notification['error']({
        message: t('errors.No Stock to specify stock box'),
      });

      return;
    }

    if(stockBox?.length >= product?.sub_series[subSeriesIndex]?.stock + product?.sub_series[subSeriesIndex]?.reserved_stock){
      notification['error']({
        message: t('errors.Stock products already put in boxes. Cant add new box'),
      });

      return;
    }

    let warehouseBox = newWarehouseBox || await handleFetchWarehouseBoxes();

    let initializedStockBox = initStockBox({
      warehouseBoxUnit: warehouseBox?.unit,
      warehouseBoxUnitNumber: warehouseBox?.unit_number,
      warehouseBoxId: warehouseBox?._id,
      userFullName: user?.full_name
    })

    if(product?.last_in_transit_stock_date){
      setStockBox(stockBox => [...stockBox, {...initializedStockBox, tempType: 'NewBox'}])
      setChangedStockBox(stockBox => [...stockBox, { ...initializedStockBox, mode: 'add', series : product?.sub_series[subSeriesIndex]?.series}])
      setProduct(product => ({ ...product, last_in_transit_stock_date: null, status: "Active" }));
      handleStockChange([...stockBox, {...initializedStockBox}], subSeriesIndex);
      return;
    }
          
    setStockBox(stockBox => [...stockBox, {...initializedStockBox}])
    setChangedStockBox(stockBox => [...stockBox, { ...initializedStockBox, mode: 'add', series : product?.sub_series[subSeriesIndex]?.series}]);

    handleStockChange([...stockBox, {...initializedStockBox}], subSeriesIndex);

    if (product?.status !== "Active") setProduct(p => ({ ...p, status: "Active" }));
  }

  const handleRemoveStockBox = (indexToRemove, box) => {
    const newStockBox = stockBox.filter((_, index) => index !== indexToRemove);
    setStockBox(newStockBox);
    handleStockChange(newStockBox, subSeriesIndex);

    if (changedStockBox.some(e => e._id === box._id && e.mode === 'add')) {
      const updatedChangedStockBox = [...changedStockBox];
      updatedChangedStockBox.splice(
        updatedChangedStockBox.findIndex(e => e._id === box._id),
        1
      );
      setChangedStockBox(updatedChangedStockBox);
      return
    }
      
    setChangedStockBox(changedStockBox => [...changedStockBox, { ...box, mode: 'remove', series : product?.sub_series[subSeriesIndex]?.series?._id}])
  };  

  const handleStockBoxChange = (change, index, oldBox) => {
    let newStockBoxEntry = {
      ...stockBox[index],
      ...change,
    };

    const newStockBox = [...stockBox]; // Create a new array
    newStockBox[index] = newStockBoxEntry;
    if(oldBox) {
      oldBox?.tempType === "OldBox" && handleRemoveStockBox(index, oldBox)
      oldBox?.tempType === "NewBox" && changedStockBox.splice(changedStockBox.findIndex(e => e._id === oldBox._id), 1)
      setChangedStockBox(changedStockBox => [...changedStockBox, { ...newStockBoxEntry, mode: 'add', series : product?.sub_series[subSeriesIndex]?.series}])
    }

    setStockBox(newStockBox);

    handleStockChange(newStockBox, subSeriesIndex);

  };

  const handleStockChange = (newStockBox, subSeriesIndex) => {
    for(let box of newStockBox){
      delete box?.tempType; 
    }
    const updatedSubSeries = [...product?.sub_series];
    updatedSubSeries[subSeriesIndex] = {
        ...updatedSubSeries[subSeriesIndex],
        stock_box: newStockBox
    };

    setProduct({ ...product, sub_series: updatedSubSeries }); 
    setIsDirty(true); 
  };

  return (
    <div className="stock-box bottom">
      <Form.Item label='Stock Box Information' >
        {stockBox?.length > 0 ? 
          stockBox?.map((box, index) => (
            <div className="address-box">
              <div className="inline-inputs-stock-box">
                <select key={box?._id} value={box?._id} className='stock-mini-box'  onChange={(e) => handleStockBoxChange( { box_id: e.target.options[e.target.selectedIndex].text, _id: e.target.value }, index, box )}>
                    {warehouseBoxes?.map((box) => (
                      <option value={box?._id}>{box?.unit}{box?.unit_number}</option>
                    ))}
                    <option value={box?._id}>{box?.box_id}</option> 
                </select>

                <Form.Item  label={`Note`} key={index}>
                  <TextArea
                    value={box?.note}
                    disabled={product?.sub_series[subSeriesIndex]?.stock + product?.sub_series[subSeriesIndex]?.reserved_stock === 0}
                    onChange={(e) => {handleStockBoxChange({ note  : e.target.value}, index)
                    setIsDirty(true);
                    }}
                  />
                </Form.Item>

                <Form.Item  label={`No Invoice`} key={index - 1}>
                  <Checkbox checked={box?.no_invoice} onChange={(e) => {handleStockBoxChange({change: { no_invoice: e.target.checked}, index})}}>
                  </Checkbox>        
                </Form.Item>

                  <Form.Item label="Stock Date">
                    <DatePicker
                      className="payment-date"
                      value={moment(box?.date)}
                      required={true}
                      onChange={(date) => handleStockBoxChange({change: { date: new Date(date) }, index})}
                    />
                  </Form.Item>

                  <Form.Item label="In Transit Stock Date">
                    <DatePicker
                      className="payment-date"
                      value={moment(box?.in_transit_stock_date)}
                      required={true}
                      onChange={(date) => handleStockBoxChange({change: { in_transit_stock_date: new Date(date) }, index})}
                    />
                  </Form.Item>

                  {box?.owner 
                    ? <Form.Item className='stock-box-owner'>
                        {t('Owner')}
                        <Input
                          value={box?.owner}
                          disabled
                        />
                      </Form.Item>
                    : null 
                  }

                  <Button type="primary" onClick={() => handleRemoveStockBox(index, box)}> Remove </Button>                                 
              </div>
            </div>
          )) 
          : null
        } 

        <Button type="primary" className='add-stock-box-button' onClick={() => handleAddStockBox()}> Add Stock Box </Button>                                 
      </Form.Item>
    </div>
  );
};

export default StockBoxEditorSubSeries;
