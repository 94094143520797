import React, { useState } from 'react';
import { Form, notification, Button} from 'antd';
import {  sendScrapers } from "../../redux/productSlice";


const ScraperGroup = ({scraperGroup, notRemovedScraperCount, product, setIsDirty, scraperGroupRemoved, setScraperGroupRemoved, uniqueKey}) => {

    const [selectedItems, setSelectedItems] = useState([]);

    const handleDeleteClick = (item) => {
        if (notRemovedScraperCount === 1 && !selectedItems.includes(item) && product.status === 'Active') {
            notification['error']({
                message: 'You cannot delete all scrapers of an active product',
            });
            return;
        }

        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setIsDirty(true);
            setSelectedItems([...selectedItems, item]);
        }

        if (scraperGroupRemoved.includes(item._id)) {
            setScraperGroupRemoved(scraperGroupRemoved.filter((selectedScraper) => selectedScraper !== item._id));
        } else {
            setScraperGroupRemoved([...scraperGroupRemoved, item._id]);
        }
    };

    const submitRemoveFromScraper = () => {
        sendScrapers(scraperGroupRemoved).then((res) => {
    
          notification['success']({
            message: 'Scrapers deleted successfully',
          });
          window.location.reload();
        })
          .catch((err) => {
            notification['error']({
              message: 'Scrapers couldn\'t deleted successfully',
            });
          });
    
    };

    const renderContent = (uniqueKey, item) => {
        if (uniqueKey === "link") {
          return (
            <span className="scraper-group-item-content">
              <span className="bold-text">Link:</span> {item.link ? `${item.link}` : ' -'}
            </span>
          );
        } else if (uniqueKey === "vendor_product_id") {
          return (
            <span className="scraper-group-item-content">
              <span className="bold-text">Vendor Product ID:</span>
              {item.vendor_product_id ? ` ${item.vendor_product_id.toString()}` : ' -'}
            </span>
          );
        }
        else if (uniqueKey === "stock_code") {
          return (
            <span className="scraper-group-item-content">
              <span className="bold-text">Stock Code:</span>
              {item.stock_code ? ` ${item.stock_code}` : ' -'}
            </span>
          );
        }
    
        return null;
    };
    

    return (
        <div>
            <Form.Item label='Scrapers' className="scraper-box">
            <div className="product-group-container">
                {scraperGroup?.map((item) => (
                    <div
                    className={`scraper-group-item ${selectedItems.includes(item) ? "faded" : ""
                        }`}
                    >
                    <span
                        className={`product-group-item-X ${item === product ? "important-x" : ""
                        }${notRemovedScraperCount === 1 && !selectedItems.includes(item) ? " disabled" : ""}`}
                        onClick={() => handleDeleteClick(item)}
                        style={{
                        color: selectedItems.includes(item) ? "red" : "black",
                        }}
                    >
                        X
                    </span>
                    <span className="scraper-group-item-content">
                        <span className="bold-text">Vendor:</span> {item.vendor ? `${item.vendor}` : '-'}
                    </span>
                    {renderContent(uniqueKey, item)}

                    <span className="scraper-group-item-content">
                        <span className="bold-text">Quantity:</span>
                        {item.quantity ? ` ${item.quantity}` : ' -'}
                    </span>
                    <span className="scraper-group-item-content">
                        <span className="bold-text">Raw Quantity:</span>
                        {item.raw_quantity ? ` ${item.raw_quantity}` : ' -'}
                    </span>
                    <span className="scraper-group-item-content">
                        <span className="bold-text">Updated At:</span>
                        {item.updatedAt ? ` ${item.updatedAt.toString()}` : ' -'}
                    </span>
                    <span className="scraper-group-item-content">
                        <span className="bold-text">Created At:</span>
                        {item.createdAt ? ` ${item.createdAt.toString()}` : ' -'}
                    </span>
                    <span className="scraper-group-item-content">
                        <span className="bold-text">Price:</span>
                        {item.price ? ` ${item.price} ${item.price_unit}` : ' -'}
                    </span>
                    <span className="scraper-group-item-content">
                        <span className="bold-text">Series:</span>
                        {item.series ? ` ${item.series}` : ' -'}
                    </span>
                    <span className="scraper-group-item-content">
                        <span className="bold-text">Status:</span>
                        {item.status ? ` ${item.status}` : ' -'}
                    </span>
                    </div>
                ))}
            </div>
        </Form.Item>
            <div className="scraper-button-area">
                <Button type="primary" onClick={() => submitRemoveFromScraper()}>
                  Submit Delete
                </Button>
              </div>
        </div>
        
    );
};

export default ScraperGroup;
