import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, notification, Modal } from 'antd';
import { fetchCategories, fetchCreate , fetchUpdate, fetchShutDown, fetchOpenVendor } from "../../redux/vendorSlice"
import { fetchUploadUrl } from "../../redux/productSlice";
import { t } from "i18next";

import EditVendorInfo from "./EditVendorInfo";
import EditBusinessInfo from "./EditBusinessInfo";
	
import "./EditVendor.scss";
import EditTranslations from "./EditTranslations";
import { LANGUAGE_CODES } from "../../constants";
import { uploadVendorBannerImage } from "../../helpers";

const EditVendor = () => {
    const [searchParams, _] = useSearchParams();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = useLocation();    

    const { name } = useParams();
    const isNew =  name === "new";
    
    const { vendors, categories, subVendors } = useSelector((state) => state.vendors);

    const [initialVendor, setInitialVendor] = useState(state);
    const [updatedVendor, setUpdatedVendor] = useState({});

    const [files, setFiles] = useState([]);
    const [initialExist, setInitialExist] = useState(false)
    const [sourceLanguage, setSourceLanguage] = useState('tr')
    const [shutDownModal, setShutDownModal] = useState(false);
    const [openVendorModal, setOpenVendorModal] = useState(false);
    const [tab, setTab] = useState("vendor-info");
    const [saveLoading, setSaveLoading] = useState(false);
    const [bannerFileList, setBannerFileList] = useState([]);

    const allwaysManualLanguages = ['en', 'tr'];

    useEffect(() => {
        setTab(searchParams?.get("tab") || "vendor-info");
    }, [searchParams?.get("tab")]);

    useEffect(() => {
        dispatch(fetchCategories());
    }, []);

    useEffect(() => {
        let currentVendor = {};
        if(!state) {
            const handle = location.pathname.split('/').pop()
            currentVendor = vendors.find(vendor => vendor?.handle === handle);
            setInitialVendor(currentVendor);
        } else {
            currentVendor = state;
        }

        setBannerFileList(currentVendor?.banner_images?.map((image, index) => {
            return {
              uid: index + 1,
              url: image,
              name: `Image_${index + 1}`,
              existBeforeUpdate: true,
              status: "done",
              is_main: currentVendor?.banner === image
            }
        }));
    }, [vendors]);
 
    useEffect(() => {
        let initials = vendors.map((vendor) => vendor?.vendor_initial)
        if(initials?.includes(updatedVendor?.vendor_initial) && isNew){
            setInitialExist(true)
        } else {
            setInitialExist(false)
        }
    }, [ updatedVendor?.vendor_initial])

    useEffect(() => {
        const formValues = {
            instagram:  initialVendor?.instagram,
            location: initialVendor?.location,
            founding_year:  initialVendor?.founding_year,
            about_en:  initialVendor?.abouts?.en,
            about_tr: initialVendor?.abouts?.tr,
            description_gb : initialVendor?.descriptions?.en,
            description_tr : initialVendor?.descriptions?.tr,
            seo_description_gb : initialVendor?.seo_abouts?.en,
            seo_description_tr : initialVendor?.seo_abouts?.tr,
            title_gb : initialVendor?.titles?.en,
            title_tr : initialVendor?.titles?.tr,
            handle : initialVendor?.handle,
            vendor_initial : initialVendor?.vendor_initial,
            lonca_vendor_margin : initialVendor?.lonca_vendor_margin,
            lonca_boutique_margin : initialVendor?.lonca_boutique_margin,
            black_friday_discount_percent : initialVendor?.black_friday_discount_percent,
            safe_stock_buffer : initialVendor?.safe_stock_buffer,
            name : initialVendor?.name,
            should_update_in_worker : initialVendor?.should_update_in_worker,
            margin_included : initialVendor?.margin_included,
            status : initialVendor?.status,
            active_on_carousel : initialVendor?.active_on_carousel,
            sellable_rules: initialVendor?.initialVendor,
            enable_tr_price : initialVendor?.enable_tr_price,
            lonca_responsible: initialVendor?.business_info?.lonca_responsible || "",
            wp_group_link: initialVendor?.business_info?.wp_group_link || "",
            xml_link: initialVendor?.business_info?.xml_link || "",
            shipment_paid_by: initialVendor?.commercial_info?.shipment_paid_by || "",
            shipment_address: initialVendor?.operations_info?.shipment_address || "",
            shipment_phone_number: initialVendor?.operations_info?.shipment_phone_number || "",
            courier_code: initialVendor?.operations_info?.courier_code || "",
            courier: initialVendor?.operations_info?.courier || "",
            shipment_vehicle: initialVendor?.operations_info?.shipment_vehicle || "",
            product_entry_type: initialVendor?.product_entry_info?.product_entry_type || "",
            product_entry_tech: initialVendor?.product_entry_info?.product_entry_tech || "",
            billing_name: initialVendor?.payment_info?.billing_name || "",
            bank_info: initialVendor?.payment_info?.bank_info || "",
            full_name: initialVendor?.payment_info?.full_name || "",
            iban: initialVendor?.payment_info?.iban || "",
            is_misfire: initialVendor?.is_misfire,
            ranking_authority: initialVendor?.ranking_authority,
            ranking_authority_test_group: initialVendor?.ranking_authority_test_group,
            misfire_limit: initialVendor?.misfire_limit,
            is_kdv_included : initialVendor?.is_kdv_included,
            is_cerebro : initialVendor?.is_cerebro,
            allow_photo_usage : initialVendor?.allow_photo_usage,
            is_telegram : initialVendor?.is_telegram,
            scraper_days: initialVendor?.scraper_days,
        }
        LANGUAGE_CODES.forEach((language) => {
            formValues[`abouts_${language}`] = initialVendor?.abouts?.[language]
            formValues[`seo_abouts_${language}`] = initialVendor?.seo_abouts?.[language]
        })

        form.setFieldsValue(formValues)

        setUpdatedVendor({
            ...updatedVendor,
            people_info: initialVendor?.people_info,
            sub_vendors: initialVendor?.sub_vendors
        });
    }, [form, initialVendor])

    const handleSubmit = async () => {
        if (!initialExist) {

            setSaveLoading(true);

            await Promise.all(files.map(async file => {

                const fileUrl = file?.productTypeSizeChart
                    ? `vendor/${initialVendor?.name?.replace(" ", "_")}/product_type_size_charts/${file?.type?.toLocaleLowerCase()}/${new Date().getTime()}.webp`
                    : `vendor/${initialVendor?.name?.replace(" ", "_")}/${file?.type?.toLocaleLowerCase()}/${new Date().getTime()}.webp`

                await fetchUploadUrl(fileUrl, "image/webp")
                    .then(async response => {
                        await fetch(response.data, {
                            method: "PUT",
                            body: file?.file,
                        })
    
                        const imageUrl = response.data.split('?')[0];

                        if (file?.productTypeSizeChart) {
                            updatedVendor.product_type_size_charts[file?.type?.toLocaleLowerCase()] = imageUrl;
                        } else {
                            updatedVendor[file?.type?.toLocaleLowerCase()] = imageUrl;
                        }
                    })
                    .catch((err) => {
                        notification['error']({
                            message: `Images couldn't uploaded successfully`
                        });
                    })
            }));

            let newBannerImageLinks = [];
           
            const newBannerImageList = bannerFileList?.filter(f => !f?.existBeforeUpdate);
            const oldBannerImageList = bannerFileList?.filter(f => f?.existBeforeUpdate);
            const oldBannerImageLinkList = oldBannerImageList?.map(image => image?.url);
            let isMainInNewBannerImageLinks = newBannerImageList?.some(image => image?.is_main);

            if(newBannerImageList?.length > 0) {
                newBannerImageLinks = await uploadVendorBannerImage(initialVendor?.name, newBannerImageList);
            }
            
            if (bannerFileList?.find(image => !image?.existBeforeUpdate)) {
                if (newBannerImageLinks.length === bannerFileList?.filter(i => !i?.existBeforeUpdate).length) {
                  notification['success']({
                    message: 'All images successfulluy uploaded to AWS!',
                  });
                } else {
                  notification['error']({
                    message: 'Error uploading images to AWS!',
                  });
                };
            }

            const mainBanner = (isMainInNewBannerImageLinks ? newBannerImageLinks?.[0] : oldBannerImageLinkList?.[0]) || "";

            newBannerImageLinks = [...(oldBannerImageLinkList || []), ...(newBannerImageLinks || [])];

            if(newBannerImageLinks.length === 0) {
                notification['error']({
                    message: 'You need to upload at least one banner for the vendor',
                });
                setSaveLoading(false);
                return;
            }

            if(!mainBanner) {
                notification['error']({
                    message: 'Please select a main banner',
                });
                setSaveLoading(false);
                return;
            }

            updatedVendor.banner_images = newBannerImageLinks.sort((firstLink, secondLink) => firstLink === mainBanner ? -1 : secondLink === mainBanner ? 1 : 0);
            updatedVendor.banner = mainBanner;

            if(isNew) {
                await fetchCreate(updatedVendor).then(async response => {
                    setSaveLoading(false);
                    notification['success']({
                        message: "Vendor created successfully"
                    });
                    navigate("/vendors")
                }).catch((err) => {
                    setSaveLoading(false);
                    notification['error']({
                        message: err.response.data.error
                    })
                        .catch((err) => {
                            notification['error']({
                                message: "Vendor couldn't created successfully"
                            });
                        })
                })
            } else {
                await fetchUpdate(initialVendor?._id, updatedVendor, sourceLanguage)
                    .then(async response => {
                        setSaveLoading(false);
                        notification['success']({
                            message: "Vendor modified successfully"
                        });
                    })
                    .catch((err) => {
                        setSaveLoading(false);
                        notification['error']({
                            message: err?.response?.data?.error
                        })
                        .catch((err) => {
                            notification['error']({
                                message: "Vendor couldn't modified successfully"
                            });
                        })
                    })
            }
            
            setUpdatedVendor(updatedVendor);

            setBannerFileList(updatedVendor?.banner_images?.map((image, index) => {
                return {
                  uid: index + 1,
                  url: image,
                  name: `Image_${index + 1}`,
                  existBeforeUpdate: true,
                  status: "done",
                  is_main: updatedVendor?.banner === image
                }
            }));
            
            setFiles([]);
        } else {
            notification['error']({
                message: 'Vendor Initials already exist'
            });
        }
    }

    
    const handleShutDown = async () => {
        await dispatch(fetchShutDown(initialVendor?._id))
            .then(async response => {
                notification['success']({
                    message: "Vendor shut down successfully"
                })
                notification['success']({
                    message: response.payload.responseMessage
                })
                setUpdatedVendor({ ...updatedVendor, should_update_in_worker: false});
                setInitialVendor({ ...initialVendor, should_update_in_worker: false });
                setShutDownModal(false)
                handleSubmit();
            }).catch((err) => {
                notification['error']({
                    message: err?.response?.data?.error
                })
                .catch((err) => {
                    setShutDownModal(false)
                    notification['error']({
                        message: "Vendor couldn't shut down successfully"
                    });
                })
            })
    }

    const handleCancelShutDown = () => {
        setShutDownModal(false);
        setUpdatedVendor({ ...updatedVendor, status: 'Active', should_update_in_worker: true });
        setInitialVendor({ ...initialVendor, status: 'Active', should_update_in_worker: true });
    }

    const handleOpenVendor = async () => {
        await dispatch(fetchOpenVendor(initialVendor?._id))
            .then(async response => {
                notification['success']({
                    message: "Vendor open successfully"
                })
                notification['success']({
                    message: response.payload.responseMessage
                })
                setUpdatedVendor({ ...updatedVendor, should_update_in_worker: true});
                setInitialVendor({ ...initialVendor, should_update_in_worker: true });
                setOpenVendorModal(false)
                handleSubmit();
            }).catch((err) => {
                notification['error']({
                    message: err?.response?.data?.error
                })
                .catch((err) => {
                    setOpenVendorModal(false)
                    notification['error']({
                        message: "Vendor couldn't open successfully"
                    });
                })
            })
    }

    const handleCancelOpenVendor = () => {
        setOpenVendorModal(false);
        setUpdatedVendor({ ...updatedVendor, status: 'Passive', should_update_in_worker: false });
        setInitialVendor({ ...initialVendor, status: 'Passive', should_update_in_worker: false });
    }
    
    return(
        <div className='container'>
            <Modal
                title="Shut Down Vendor"
                visible={shutDownModal}
                onCancel={handleCancelShutDown}
                footer={[
                    <div>
                    <Button onClick={() => {setShutDownModal(false); handleSubmit();}}>No</Button>
                    <Button onClick={() => handleShutDown(false)}>Yes</Button>
                    </div>
                ]}
            >
                <p>{t("fields.edit_order.Do you want to archive all products? Products in stock will remain active.")}</p>
            </Modal>
            <Modal
                title="Open Vendor"
                visible={openVendorModal}
                onCancel={handleCancelOpenVendor}
                footer={[
                    <div>
                    <Button onClick={() => {setOpenVendorModal(false); handleSubmit();}}>No</Button>
                    <Button onClick={() => handleOpenVendor(false)}>Yes</Button>
                    </div>
                ]}
            >
                <p>Do you want to make active all available products? (This action works only for product entry type : "Admin Panel" or "Supplier Panel")</p>
            </Modal>

            <div className='product-details'>
                <Form
                    form={form}
                    className="vendor-form"
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <div className="header-save vendor-header-save">
                        <div className="vendor-tabs">
                            <div onClick={() => navigate(`${location.pathname}?tab=vendor-info`)} className={tab === "vendor-info" && "selected"}>Vendor Info</div>
                            <div onClick={() => navigate(`${location.pathname}?tab=business-info`)} className={tab === "business-info" && "selected"}>Business Info</div>
                            <div onClick={() => navigate(`${location.pathname}?tab=translations`)} className={tab === "translations" && "selected"}>Translations</div>
                        </div>
                        <div>
                            <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }} loading={saveLoading}>
                                {(`Save`)}
                            </Button>
                            <Button type="secondary" onClick={() => {navigate("/vendors")}}>
                               Cancel
                            </Button>
                        </div>
                    </div>

                    <div className="edit-vendor-container">
                        {
                            tab === "vendor-info" 
                            ? 
                                <EditVendorInfo 
                                    updatedVendor={updatedVendor}
                                    setUpdatedVendor={setUpdatedVendor}
                                    initialVendor={initialVendor}
                                    setInitialVendor={setInitialVendor}
                                    isNew={isNew}
                                    files={files}
                                    setFiles={setFiles}
                                    sourceLanguage={sourceLanguage}
                                    setSourceLanguage={setSourceLanguage}
                                    form={form}
                                    allwaysManualLanguages={allwaysManualLanguages}
                                    bannerFileList={bannerFileList}
                                    setBannerFileList={setBannerFileList}
                                /> 
                            : tab === "business-info"

                             ?   <EditBusinessInfo 
                                    updatedVendor={updatedVendor}
                                    setUpdatedVendor={setUpdatedVendor}
                                    initialVendor={initialVendor}
                                    setInitialVendor={setInitialVendor}
                                    isNew={isNew}
                                    categories={categories}
                                    setShutDownModal={setShutDownModal}
                                    setOpenVendorModal={setOpenVendorModal}
                                    subVendors={subVendors}
                                />
                                : <EditTranslations 
                                    updatedVendor={updatedVendor}
                                    setUpdatedVendor={setUpdatedVendor}
                                    initialVendor={initialVendor}
                                    setInitialVendor={setInitialVendor}
                                    isNew={isNew}
                                    files={files}
                                    setFiles={setFiles}
                                    sourceLanguage={sourceLanguage}
                                    setSourceLanguage={setSourceLanguage}
                                    allwaysManualLanguages={allwaysManualLanguages}
                                /> 
                        }
                    </div>
                    
                </Form>
            </div>
        </div>
    )
};

export default EditVendor;