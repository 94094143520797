import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { fetchNewProducts, setFilter, fetchUniqueVendors } from '../../redux/newProductsSlice';

import { Pagination, NewProduct, SearchBar} from '../../components';
import { Form, Select, Spin } from 'antd';

import './NewProducts.css';

function NewProducts() {
    const dispatch = useDispatch();
    const { filter, newProducts, productCount } = useSelector((state) => state.newProducts);
    const [form] = Form.useForm();
    const [vendors, setVendors] = useState([]);
    const [activeVendors, setActiveVendors] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('translation');

    const handleFilter = (payload) => {
        dispatch(setFilter(payload));
    };

    useEffect(() => {
        (async () => {
            const uniqueVendors = await fetchUniqueVendors();
            const vendorNames = uniqueVendors?.map(v => v?.name);
            const activeVendorNames = uniqueVendors?.filter(v => v?.status === "Active")?.map(v => v?.name);
            setVendors(vendorNames);
            setActiveVendors(activeVendorNames);
        })()
    }, [])

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let isCancelled = false;

        const fetchData = async () => {
          setLoading(true);
          try {
            await dispatch(fetchNewProducts({filter, vendors, signal}));
          } catch (error) {
          } finally {
            if (!isCancelled) {
            setLoading(false); 
          }
          }
        };
        fetchData();

        return () => {
            isCancelled = true;
            controller.abort();
        };

      }, [filter, dispatch, vendors]);

 
    const { Option } = Select;

    return (
        <div className='new-products-container'>
            <h2 className='title'>New Products</h2>
            <div className='list-number-of-products'>
                    Number of products: {productCount}
                </div>
            <div className="list-products-filter" >
                <div className='search-products'>
                <SearchBar type="new-products"/>
                </div>
                <Form
                    form={form}
                    layout="inline"
                    initialValues={{
                        product: filter?.filter.product ? filter?.filter.product : "{}",
                        code: filter?.filter.code ? filter?.filter.code : "{}",
                        vendor: filter?.filter.vendor ? filter?.filter.vendor : "all",
                        status: filter?.filter.status ? filter?.filter.status : "all",
                        images: filter?.filter.images ? filter?.filter.images : "all",
                        vendor_status: filter?.filter.vendor_status ? filter?.filter.vendor_status : "all",
                    }}
                >
                    <Form.Item name="product">
                        <Select
                            onChange={(e) => {
                                handleFilter({ ...filter, sort: e });
                            }}
                        >
                            <Option value="{}">Product Name: All</Option>
                            <Option value='{"name": 1}'>Product Name: A - Z</Option>
                            <Option value='{"name": -1}'>Product Name: Z - A</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="code">
                        <Select
                            onChange={(e) => {
                                handleFilter({ ...filter, sort: e });
                            }}
                        >
                            <Option value="{}">Code: All</Option>
                            <Option value='{"stock_code": 1}'>Code: A - Z</Option>
                            <Option value='{"stock_code": -1}'>Code: Z - A</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="images">
                        <Select
                            onChange={(e) => {
                                if (e === 'all') {
                                    handleFilter({ ...filter, filter: { ...filter.filter, images: "all" } });
                                } else {
                                    handleFilter({ ...filter, filter: { ...filter.filter, images: e } });
                                }
                            }}
                        >
                            <Option value="all">Image: All</Option>
                            <Option value="yes">With Image</Option>
                            <Option value="no">Without Image</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="vendor">
                        <Select
                            onChange={(e) => {
                                if (e === 'all') {
                                    handleFilter({ ...filter, filter: { ...filter.filter, vendor: { $exists: true } } });
                                } else {
                                    handleFilter({ ...filter, filter: { ...filter.filter, vendor: e } });
                                }
                            }}
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: "100px" }}
                        >
                            <Option value="all">All Vendors</Option>
                            {vendors?.slice().sort((a, b) => a.localeCompare(b)).map((vendor, index) => (
                                <Option key={'vendor-select' + index} value={vendor}>
                                    {vendor}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="status">
                        <Select
                            onChange={(e) => {
                                if (e === 'all') {
                                    handleFilter({ ...filter, filter: { ...filter.filter, status: { $exists: true } } });
                                } else {
                                    handleFilter({ ...filter, filter: { ...filter.filter, status: e } });
                                }
                            }}
                        >
                            <Option value="all">All Status</Option>
                            <Option value="Active">{t('fields.new_products.Active')}</Option>
                            <Option value="Passive">{t('fields.new_products.Passive')}</Option>
                            <Option value="Archived">{t('fields.new_products.Archived')}</Option>
                            <Option value="Problematic">{t('fields.new_products.Problematic')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="vendor_status">
                        <Select
                            onChange={(e) => {
                                if (e === 'all') {
                                    handleFilter({ ...filter, filter: { ...filter.filter, vendor: { $exists: true } } });
                                } else if(e === "Active"){
                                    handleFilter({ ...filter, filter: { ...filter.filter, vendor: { $in: activeVendors} } });
                                } else if(e === "Passive"){
                                    handleFilter({ ...filter, filter: { ...filter.filter, vendor: { $nin: activeVendors} } });
                                }
                            }}
                        >
                            <Option value="all">Vendor Status</Option>
                            <Option value="Active">Active</Option>
                            <Option value="Passive">Passive</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </div>
            <div className="list-new-products-table-head">
                <div className="list-new-products-table-check">*</div>
                <div className="list-new-products-table-product">Product</div>
                <div className="list-new-products-table-status">Status</div>
                <div className="list-new-products-table-vendor">Vendor</div>
                <div className="list-new-products-table-price">Price</div>
                <div className="list-new-products-table-code">Code</div>
            </div>
            {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading New Products..."} />}
            {newProducts?.map((item) => <NewProduct key={item?._id} item={item} />)}
            <div>
                <Pagination filter={filter} setFilter={handleFilter} />
            </div>
        </div>
    );

}

export default NewProducts;
