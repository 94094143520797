import "./Shelve.scss";
import React, { useState, useEffect } from "react";
import { Modal, Checkbox, Select, Card, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isRoleHavePermission } from "../../../helpers";
import { updateExchangeBox, emptyOrderBox } from "../../../redux/warehouseBoxSlice";

function Shelve({
  shelve,
  shelveUpdate,
  warehouseBoxes,
  fetchBoxesToggle,
  setFetchBoxesToggle,
  packagableOrders,
  exchangeOrders,
  tab
}) {
  const { t } = useTranslation("translation");
  const [isFull, setIsFull] = useState(shelve?.is_full);
  const [isDisabled, setIsDisabled] = useState(shelve?.is_disabled);
  const { user } = useSelector((state) => state.user);

  const [selectedOrder, setSelectedOrder] = useState({});
  const allowedAdminRoles = ['Admin', 'Operations']
  const [modalVisible, setModalVisible] = useState(false);
  
  useEffect(() => {
    const currentPackOrder = packagableOrders.find((order) => order?.unique_order_id === shelve?.unique_order_id)
    setSelectedOrder(currentPackOrder || {});
  }, [shelve, packagableOrders]);

  const groupProductsByProductId = (products) => {
    const groupedProducts = {};

    products.forEach((product) => {
      const codeAndName = product.product_code + product.product_name;
      if (!groupedProducts[codeAndName]) {
        groupedProducts[codeAndName] = {
          ...product,
          count: 1,
        };
      } else {
        groupedProducts[codeAndName].count += 1;
      }
    });

    return Object.values(groupedProducts);
  };
  const groupedProducts = groupProductsByProductId(shelve?.products);

  const handleEmpty = async () => {
    try {
      await emptyOrderBox({ shelveId: shelve?._id, orderId : shelve?.unique_order_id})
      notification["success"]({
        message: 'Box has been emptyed!'
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch(err) {
      notification["error"]({
        message: err.response.data.error
      });
    }
  }

  const handleUpdate = async () => {
    if(tab === "exchange-box") {
      if(groupedProducts?.length > 0 && selectedOrder?.unique_order_id !== shelve?.unique_order_id) {
        notification["error"]({
          message: "You need to empty this box before changing it to another order!"
        });
        return;
      }
 
      updateExchangeBox({ order: selectedOrder, exchangeBoxData: { isFull: isFull, isDisabled: isDisabled, shelveId: shelve?._id } }).then(response => {
         notification["success"]({
          message:
            "Box Updated Successfully"
        })}).catch(err => {
          notification["error"]({
            message: "Error updating shelve"
          });
      })
      setFetchBoxesToggle(!fetchBoxesToggle);
      setModalVisible(false);

      return;
    }

    const isOrderPackagable = !!packagableOrders.find(order => order?.unique_order_id === shelve?.unique_order_id)
    const emptyShelve = !shelve?.unique_order_id;
    const fullUpdateWithoutOrder = isFull && emptyShelve && shelve?.type === 'order';
    const boxType = shelve?.type;
    const orderHasOtherActiveWarehouseBox =
      boxType === 'order' && !isFull &&
      warehouseBoxes?.find(
        (box) =>
          box?.unique_order_id === (shelve?.unique_order_id || selectedOrder?.unique_order_id) &&
          !box?.is_full
      );


    if (orderHasOtherActiveWarehouseBox && !emptyShelve) {
      notification["error"]({
        message: t(
          "shelves.An order can't have two active boxes at the same time"
        ),
      });
      return;
    }
    if (fullUpdateWithoutOrder) {
      notification["error"]({
        message: t("shelves.A box can't be full without order"),
      });
      return;
    }
    let update = {};
    if (isFull !== shelve?.is_full) update.is_full = isFull;
    if (isDisabled !== shelve?.is_disabled) update.is_disabled = isDisabled;

    if (selectedOrder?.unique_order_id !== shelve?.unique_order_id)
      update.unique_order_id = selectedOrder?.unique_order_id;
    if (selectedOrder?.customer_name !== shelve?.customer_name)
      update.customer_name = selectedOrder?.customer_name;

    const data = {
      boxId: shelve?._id,
      update,
      isOrderPackagable,
    };

    shelveUpdate(data)
      .then((res) => {
        res?.added
          ? notification["success"]({
            message:
              t("shelves.update_new_box") +
              ": " +
              res?.box?.unit +
              res?.box?.unit_number,
          })
          : notification["success"]({
            message: t("shelves.Update Successful"),
          });
        setFetchBoxesToggle(!fetchBoxesToggle);
        setModalVisible(false);
      })
      .catch((err) => {
        notification["error"]({
          message: err.response.data.message,
        });
      });

  };
  const showCardInfo = (shelve) => {
    
    if (shelve?.type === "order") {
      return (
        shelve?.unique_order_id ? (
          <div className="order-info">
            <p>{shelve?.is_full ? t("shelves.Full") : null}</p>
            <p>{shelve?.customer_name}</p>
            <p>{shelve?.unique_order_id}</p>
            <p>{selectedOrder?.status}</p>
          </div>
        ) : (
          <div className="order-info">
            <p>
              {shelve?.is_disabled
                ? t("shelves.Disabled shelve")
                : t("shelves.Empty shelve")}
            </p>
            <br />
          </div>
        )
      )
    } else if(shelve?.type === "stock") {
      if (shelve?.products?.length > 0) {
        return (
          <div className="shelve-product-info">
            {groupedProducts.map((product) => (
              <div key={product?.parent_product_id}>
                <p>{`${product.count > 1 ? `x${product.count}` : ''} ${product?.product_name}`}</p>
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div className="shelve-product-info">
            <p>
              {shelve?.is_disabled
                ? t("shelves.Disabled shelve")
                : t("shelves.Empty shelve")}
            </p>
            <br />
          </div>
        )
      }
    } else if(shelve?.type === "exchange") {
      return (
        shelve?.unique_order_id ? (
          <div className="order-info">
            <p>{shelve?.is_full ? t("shelves.Full") : null}</p>
            <p>{shelve?.customer_name}</p>
            <p>{shelve?.unique_order_id}</p>
          </div>
        ) : (
          <div className="order-info">
            <p>
              {shelve?.is_disabled
                ? t("shelves.Disabled shelve")
                : t("shelves.Empty shelve")}
            </p>
            <br />
          </div>
        )
      )
    }
  }

  return (
    <div>
      <Card
        className={`shelve-container 
            ${shelve?.is_full ? "full" : ""} 
            ${(shelve?.unique_order_id || shelve?.products?.length > 0) && !shelve?.is_full
              ? "not-full-order"
              : ""
            }
            ${shelve?.is_disabled ? "disabled" : ""} 
            ${selectedOrder?.status == "Cancelled" ? "cancelled" : null}
            `}
        onClick={() => setModalVisible(true)}
        title={
          <div className={shelve?.type === 'stock' && "shelve-stock-title"}>
            {shelve?.unit + shelve?.unit_number}
            <br />
            {shelve?.type === "stock" && shelve?.type}
          </div>
        }

      >
        {showCardInfo(shelve)}

      </Card>
      <Modal
        title={shelve?.unit + shelve?.unit_number}
        visible={modalVisible}
         onCancel={() => setModalVisible(false)}
        // onOk={handleUpdate}
        footer={shelve?.type === "order" ? [
          <button onClick={handleEmpty} disabled={selectedOrder?.status !== "Cancelled" || !isRoleHavePermission(allowedAdminRoles, user?.admin_role)} key="3" type="primary" style={{marginRight:"20px"}}>
          Empty
        </button>,
          <button key="1" type="primary" style={{marginRight:"20px"}} onClick={() => setModalVisible(false)}>
            Cancel
          </button>,
          <button key="2" type="primary" onClick={handleUpdate}>
          Ok
        </button>
        ] : null}
      >
        <Checkbox
          disabled={shelve?.is_disabled}
          checked={isFull}
          onChange={(e) => setIsFull(e.target.checked)}
        >
          {t("shelves.Is Full")}
        </Checkbox>
        <Checkbox
          checked={isDisabled}
          disabled={shelve?.unique_order_id || !isRoleHavePermission(allowedAdminRoles, user?.admin_role)}
          onChange={(e) => setIsDisabled(e.target.checked)}
        >
          {t("shelves.Is Disabled")}
        </Checkbox>
        {shelve?.type === "order" && (
          <Select
            disabled={shelve?.is_disabled}
            defaultValue={shelve?.unique_order_id}
            showSearch
            onChange={(value) => {
              setSelectedOrder(
                packagableOrders.find((order) => order.unique_order_id === value)
              );
            }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionValue = option.props.value;
              const optionKey = option.props.key;
              return (
                optionValue.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                optionKey?.indexOf(input.toLowerCase()) >= 0
              );
            }}
            style={{ width: "100%" }}
            placeholder={t("shelves.Select an Order")}
          >
            {packagableOrders?.map((order) => (
              <Option key={order?.unique_order_id} value={order?.unique_order_id}>
                <div>
                  <div>
                    {order?.unique_order_id} - {order?.customer_name}
                  </div>
                  <div>
                    {" "}
                    {t("shelves.Status")}: {order?.status}
                  </div>
                  <div>
                    {t("shelves.Payment Date")}:{" "}
                    {new Date(order?.payment_at).toLocaleDateString()}
                  </div>
                </div>
              </Option>
            ))}
          </Select>
          
        )}

        {shelve?.type === "exchange" && (
          <Select
            disabled={shelve?.is_disabled}
            defaultValue={shelve?.unique_order_id}
            showSearch
            onChange={(value) => {
              setSelectedOrder(
                exchangeOrders.find((order) => order.unique_order_id === value)
              );
            }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionValue = option.props.value;
              const optionKey = option.props.key;
              return (
                optionValue.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                optionKey?.indexOf(input.toLowerCase()) >= 0
              );
            }}
            style={{ width: "100%" }}
            placeholder={t("shelves.Select an Order")}
          >
            {exchangeOrders?.map((order) => (
              <Option key={order?.unique_order_id} value={order?.unique_order_id}>
                <div>
                  <div>
                    {order?.unique_order_id} - {order?.customer_name}
                  </div>
                  <div>
                    {t("shelves.Order Exchange Status")}: {order?.refund_status}
                  </div>
                  <div>
                    {t("shelves.Payment Date")}:{" "}
                    {new Date(order?.payment_at).toLocaleDateString()}
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        )}
        {shelve?.type === "stock" && (
          <div className="shelve-modal-stock-info">
            {groupedProducts.length > 0 ? groupedProducts.map((product) => (
              <div className="stock-info" key={product.parent_product_id}>
                <p>{`${product.count > 1 ? `x${product.count}` : ''} ${product?.product_name}`}</p>
              </div>
            )) : <div>{t('shelves.empty')}</div>}
          </div>

        )}
        {shelve?.type === "exchange" && (
          <ul className="shelve-modal-exchange-info">
            {groupedProducts?.map(product => (
              <li className="exchange-info">
                {product?.product_name || product?.product_code}
              </li>
            ))}
          </ul> 

        )}

        
      </Modal>
    </div>
  );
}

export default Shelve;
