import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Checkbox, Radio, notification, Cascader } from 'antd';
import ButtonGroup from "antd/lib/button/button-group";
import { DeleteOutlined } from '@ant-design/icons';
const { SHOW_CHILD } = Cascader;
import "./EditVendor.scss";

const EditBusinessInfo = ({
    updatedVendor,
    setUpdatedVendor,
    initialVendor,
    setInitialVendor,
    isNew,
    categories,
    setShutDownModal,
    setOpenVendorModal,
    subVendors
}) => {

    const { Option } = Select;

    const [hoverText, setHoverText] = useState('dsfs');
    const [showHoverText, setShowHoverText] = useState(false);
    const [addNewPeople, setAddNewPeople] = useState(false);
    const [draftPerson, setDraftPerson] = useState({});

    const [addNewSubVendor, setAddNewSubVendor] = useState(false);
    const [draftSubVendor, setDraftSubVendor] = useState({});

    const allRegions = [
        { label: 'TURKEY', value: 'Turkey' },
        { label: 'EUROPE_BIG', value: 'Europe Big' },
        { label: 'EUROPE_SMALL', value: 'Europe Small' },
        { label: 'OTHER TURKEY', value: 'Other Turkey' },
        { label: 'OTHER EUROPE', value: 'Other Europe' },
        { label: 'NEIGHBOR', value: 'Neighbor' },
        { label: 'OTHERS', value: 'Others' },
      ];

      const initializeSellableRules = (initialVendor, allRegions) => {
        return allRegions.map(region => {
          const existingRule = initialVendor?.sellable_rules?.find(rule => rule.name === region.value);
          return existingRule ? existingRule : { type: 'region', name: region.value, sellable: true };
        });
      };
      
      const [sellableRules, setSellableRules] = useState([]);
      const [defaultCascaderValue, setDefaultCascaderValue] = useState([]);
      
      useEffect(() => {
        if (initialVendor && sellableRules.length === 0) {
          const initializedRules = initializeSellableRules(initialVendor, allRegions);
          setSellableRules(initializedRules);
        }
      }, [initialVendor, allRegions, sellableRules.length]);
      
      useEffect(() => {
        if (sellableRules.length > 0) {
          setDefaultCascaderValue(sellableRules.filter(rule => rule.sellable).map(rule => [rule.name]));
        }
      }, [sellableRules]);
      
      const handleSellableRuleChange = (selectedValues) => {
        let selectedValuesList = selectedValues?.map(value => value?.[0]);
        const updatedRules = sellableRules.map(rule =>
          ({ ...rule, sellable: selectedValuesList.includes(rule.name) })
        );
        setSellableRules(updatedRules);
        setBusinessInfo(false, "sellable_rules", updatedRules);
      };

      const sellableOptions = allRegions.map(region => ({ value: region.value, label: region.label }));


    const setBusinessInfo = (parentField, field, value) => {
        if (parentField) {
            setUpdatedVendor({
                ...updatedVendor,
                [`${parentField}.${field}`]: value
            })
            setInitialVendor({
                ...initialVendor,
                [parentField]: {
                    ...initialVendor[parentField],
                    [field]: value
                }
            })
        } else {

            setUpdatedVendor({
                ...updatedVendor,
                [field]: value
            })
            setInitialVendor({
                ...initialVendor,
                [field]: value
            })
        }
    }

    const handleMouseEnter = (category) => {
        const hierarchyText = [...category?.hierarchy?.slice().sort((a, b) => a.category_ranking - b.category_ranking).map((x) => x.name)]
        setHoverText(`Hovered Category: ${hierarchyText.join(' > ')} > ${category.name}`);
        setShowHoverText(true);
      };
    
    const handleMouseLeave = () => {
        setShowHoverText(false);
    };

    const handleAddNewPerson = () => {
        const toBeAddedPerson = { ...draftPerson };
        setDraftPerson({});

        setUpdatedVendor({
            ...updatedVendor,
            people_info: [
                ...(updatedVendor?.people_info || []),
                toBeAddedPerson
            ]
        });

        setInitialVendor({
            ...initialVendor,
            people_info: [
                ...(initialVendor?.people_info || []),
                toBeAddedPerson
            ]
        });

        setAddNewPeople(false);
    }

    const handleAddNewSubVendor = () => {
        const toBeAddedSubVendor = { ...draftSubVendor };
        setDraftSubVendor({});

        setUpdatedVendor({
            ...updatedVendor,
            sub_vendors: [
                ...(updatedVendor?.sub_vendors || []),
                toBeAddedSubVendor
            ]
        });

        setInitialVendor({
            ...initialVendor,
            sub_vendors: [
                ...(initialVendor?.sub_vendors || []),
                toBeAddedSubVendor
            ]
        });

        setAddNewSubVendor(false);
    }

    const handlePersonDelete = (personId) => {
        setUpdatedVendor({
            ...updatedVendor,
            people_info: updatedVendor?.people_info?.filter(p => p?._id?.toString() !== personId?.toString())
        })

        setInitialVendor({
            ...initialVendor,
            people_info: initialVendor?.people_info?.filter(p => p?._id?.toString() !== personId?.toString())
        })
    }

    const handleSubVendorDelete = (vendorName) => {
        setUpdatedVendor({
            ...updatedVendor,
            sub_vendors: updatedVendor?.sub_vendors?.filter(p => p?.name !== vendorName)
        })

        setInitialVendor({
            ...initialVendor,
            sub_vendors: initialVendor?.sub_vendors?.filter(p => p?.name !== vendorName)
        })
    }

    return (
        <div className="business-info-upper-container">
            <div className="business-info-container">
                <h3 className="section-title">Business</h3>

                <div className="section">
                    <div className="inline-three-inputs-vendor">
                        <Form.Item label={(`Lonca Responsible`)}  name="lonca_responsible">
                            <Input
                                value={initialVendor?.business_info?.lonca_responsible}
                                placeholder="Lonca Responsible"
                                onChange={(e) => {
                                    setBusinessInfo("business_info", "lonca_responsible",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`Whatsapp Group Link`)}  name="wp_group_link">
                            <Input
                                value={initialVendor?.business_info?.wp_group_link}
                                placeholder="Whatsapp Group Link"
                                onChange={(e) => {
                                    setBusinessInfo("business_info", "wp_group_link",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`XML Link`)}  name="xml_link">
                            <Input
                                value={initialVendor?.business_info?.xml_link}
                                placeholder="XML Link"
                                onChange={(e) => {
                                    setBusinessInfo("business_info", "xml_link",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>
                    </div>

                    <div className="inline-three-inputs-vendor">
                        <div className="checkbox-container">
                            <span className="checkbox-title">Sales Channel:</span>

                            <Checkbox 
                                checked={initialVendor?.business_info?.sales_channel?.includes("Wholesale")} 
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setBusinessInfo("business_info", "sales_channel",  [ ...(initialVendor?.business_info?.sales_channel || []), "Wholesale" ]);
                                    } else {
                                        setBusinessInfo("business_info", "sales_channel",  (initialVendor?.business_info?.sales_channel || [])?.filter(c => c !== "Wholesale"));
                                    }
                                }}
                            >
                                Wholesale
                            </Checkbox>

                            <Checkbox 
                                checked={initialVendor?.business_info?.sales_channel?.includes("Retail")} 
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setBusinessInfo("business_info", "sales_channel",  [ ...(initialVendor?.business_info?.sales_channel || []), "Retail" ]);
                                    } else {
                                        setBusinessInfo("business_info", "sales_channel",  (initialVendor?.business_info?.sales_channel || [])?.filter(c => c !== "Retail"));
                                    }
                                }}
                            >
                                Retail
                            </Checkbox>
                        </div>

                        <div className="checkbox-container">
                            <span className="checkbox-title">Panel User:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("business_info", "panel_user",  e.target.value);
                                }} 
                                value={typeof initialVendor?.business_info?.panel_user !== "undefined" ? initialVendor?.business_info?.panel_user : false}
                            >
                                <Radio.Button value={true}>True</Radio.Button>
                                <Radio.Button value={false}>False</Radio.Button>
                            </Radio.Group>
                        </div>

                        <div className="checkbox-container">
                            <span className="checkbox-title">XML/CSV Price Unit:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo(false, "price_unit", e.target.value);
                                }} 
                                value={typeof initialVendor?.price_unit !== "undefined" ? initialVendor?.price_unit : "USD"}
                            >
                                <Radio.Button value="USD">USD</Radio.Button>
                                <Radio.Button value="TRY">TRY</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </div>
            </div>

            <div className="business-info-container">
                <h3 className="section-title">Commercial</h3>
                <div className="section">               
                    <div className="inline-two-inputs-vendor-long-right">
                        <Form.Item label={(`Status`)} name="status" rules={[
                                    {required: true}]}>
                            <Select
                                value={initialVendor?.status}
                                onChange={(e) => {
                                    if(e === "Active"){
                                        if(isNew) {
                                            notification['error']({
                                                message: "You can't create vendor with 'Active' status!"
                                            });
                                        } else {
                                            setUpdatedVendor({ ...updatedVendor, status: 'Active', should_update_in_worker: true });
                                            setInitialVendor({ ...initialVendor, status: 'Active', should_update_in_worker: true });
                                            setOpenVendorModal(true)
                                        }
                                    } else if (e === "Passive") {
                                        setBusinessInfo(false, "status", e);
                                        if(!isNew){
                                            setShutDownModal(true);
                                        } 
                                    }
                                }}>
                                    <Option value="Active">Active</Option>
                                    <Option value="Passive">Passive</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Select Sellable Regions">
                            <Cascader
                                key={defaultCascaderValue}
                                style={{ width: '100%' }}
                                options={sellableOptions}
                                onChange={handleSellableRuleChange}
                                multiple
                                maxTagCount="responsive"
                                showCheckedStrategy={SHOW_CHILD}
                                defaultValue={defaultCascaderValue}
                                placeholder="Select sellable regions"
                            />
                        </Form.Item>
                    </div>

                    <div className="inline-four-inputs-vendor">
                        <Form.Item label={(`Different TR Price`)} name="enable_tr_price">
                            <Select
                                value={initialVendor?.enable_tr_price }
                                onChange={(e) => {
                                    setBusinessInfo(false, "enable_tr_price", e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={(`BIG Seller Authority`)} name="ranking_authority_test_group">
                            <Select
                                value={initialVendor?.ranking_authority_test_group }
                                onChange={(e) => {
                                    setBusinessInfo(false, "ranking_authority_test_group", e);
                                }}>
                                     {[...Array(10).keys()].map((z) => (
                                     <Option key={z} value={z+1}>{z+1}</Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label={(`Other Seller Authority`)} name="ranking_authority">
                            <Select
                                value={initialVendor?.ranking_authority }
                                onChange={(e) => {
                                    setBusinessInfo(false, "ranking_authority", e);
                                }}>
                                     {[...Array(10).keys()].map((z) => (
                                     <Option key={z} value={z+1}>{z+1}</Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label={(`Is Misfire`)} name="is_misfire">
                            <Select
                                value={initialVendor?.is_misfire }
                                onChange={(e) => {
                                    setBusinessInfo(false, "is_misfire", e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={(`Misfire Item Limit`)}  name="misfire_limit">
                            <Input
                                type= "number"
                                min={0}
                                step="1"
                                value={initialVendor?.misfire_limit}
                                placeholder="0"
                                onChange={(e) => {
                                    setBusinessInfo(false, "misfire_limit", Number(e.target.value));
                                }}>
                            </Input>
                        </Form.Item>
                        <Form.Item label={(`Is Cerebro`)} name="is_cerebro">
                            <Select
                                value={initialVendor?.is_cerebro}
                                onChange={(e) => {
                                    setBusinessInfo(false, "is_cerebro",  e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>

                        </Form.Item>
                        <Form.Item label={(`Is Telegram`)} name="is_telegram">
                            <Select
                                value={initialVendor?.is_telegram }
                                onChange={(e) => {
                                    setBusinessInfo(false, "is_telegram", e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={(`Scraper Days`)}  name="scraper_days">
                            <Input
                                type= "number"
                                min={0}
                                step="1"
                                value={initialVendor?.scraper_days}
                                placeholder="0"
                                onChange={(e) => {
                                    setBusinessInfo(false, "scraper_days", Number(e.target.value));
                                }}>
                            </Input>
                        </Form.Item>
                    </div>

                    <div className="inline-four-inputs-vendor">
                        <Form.Item label={(`Active On Carousel`)} name="active_on_carousel">
                            <Select
                                value={initialVendor?.active_on_carousel}
                                onChange={(e) => {
                                    setBusinessInfo(false, "active_on_carousel", e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={(`Lonca Vendor Margin`)}  name="lonca_vendor_margin">
                            <Input
                                type= "number"
                                required
                                min={0}
                                step="0.01"
                                value={initialVendor?.lonca_vendor_margin}
                                placeholder="0"
                                onChange={(e) => {
                                    setBusinessInfo(false, "lonca_vendor_margin", Number(e.target.value));
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`Lonca Boutique Margin`)}  name="lonca_boutique_margin">
                            <Input
                                type= "number"
                                required
                                min={0}
                                step="0.01"
                                value={initialVendor?.lonca_boutique_margin}
                                placeholder="0"
                                onChange={(e) => {
                                    setBusinessInfo(false, "lonca_boutique_margin", Number(e.target.value));
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`Black Friday Discount Percent`)}  name="black_friday_discount_percent">
                            <Input
                                type= "number"
                                min={0}
                                step="1"
                                max={100}
                                value={initialVendor?.black_friday_discount_percent}
                                placeholder="0"
                                onChange={(e) => {
                                    setBusinessInfo(false, "black_friday_discount_percent", Number(e.target.value));
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`Allow Photo Usage`)} name="allow_photo_usage">
                            <Select
                                value={initialVendor?.allow_photo_usage}
                                onChange={(e) => {
                                    setBusinessInfo(false, "allow_photo_usage", e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <div className="inline-three-inputs-vendor">
                        <div className="checkbox-container">
                            <span className="checkbox-title">Shipment paid by:</span>

                            <Form.Item name="shipment_paid_by">
                                <Radio.Group 
                                    onChange={(e) => {
                                        setBusinessInfo("commercial_info", "shipment_paid_by",  e.target.value);
                                    }} 
                                    value={initialVendor?.commercial_info?.shipment_paid_by}
                                >
                                    <Radio.Button value="Lonca">Lonca</Radio.Button>
                                    <Radio.Button value="Vendor">Vendor</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>

            <div className="business-info-container">
                <h3 className="section-title">Operations</h3>

                <div className="section">

                    <div>
                        <Form.Item label={(`Shipment Address`)}  name="shipment_address">
                            <Input
                                value={initialVendor?.operations_info?.shipment_address}
                                placeholder="Shipment Address"
                                onChange={(e) => {
                                    setBusinessInfo("operations_info", "shipment_address",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>
                    </div>

                    <div className="inline-three-inputs-vendor">
                        <Form.Item label={(`Shipment Phone Number`)}  name="shipment_phone_number">
                            <Input
                                value={initialVendor?.operations_info?.shipment_phone_number}
                                placeholder="Shipment Phone Number"
                                onChange={(e) => {
                                    setBusinessInfo("operations_info", "shipment_phone_number",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`Courier Code`)}  name="courier_code">
                            <Input
                                value={initialVendor?.operations_info?.courier_code}
                                placeholder="Courier Code"
                                onChange={(e) => {
                                    setBusinessInfo("operations_info", "courier_code",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>

                        <div className="checkbox-container">
                            <span className="checkbox-title">Courier:</span>

                            <Form.Item name="courier">
                                <Radio.Group 
                                    onChange={(e) => {
                                        setBusinessInfo("operations_info", "courier",  e.target.value);
                                    }} 
                                    value={initialVendor?.operations_info?.courier}
                                >
                                    <Radio.Button value="MNG">MNG</Radio.Button>
                                    <Radio.Button value="Sürat">Sürat</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="inline-three-inputs-vendor">
                        <div className="checkbox-container">
                            <span className="checkbox-title">Shipment vehicle:</span>

                            <Form.Item name="shipment_vehicle">
                                <Radio.Group 
                                    onChange={(e) => {
                                        setBusinessInfo("operations_info", "shipment_vehicle",  e.target.value);
                                    }} 
                                    value={initialVendor?.operations_info?.shipment_vehicle}
                                >
                                    <Radio.Button value="Own Vehicle">Own Vehicle</Radio.Button>
                                    <Radio.Button value="Courier">Courier</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <div className="checkbox-container">
                            <span className="checkbox-title">Physical invoice sent:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("operations_info", "physical_invoice_sent",  e.target.value);
                                }} 
                                value={typeof initialVendor?.operations_info?.physical_invoice_sent !== "undefined" ? initialVendor?.operations_info?.physical_invoice_sent : false}
                            >
                                <Radio.Button value={true}>True</Radio.Button>
                                <Radio.Button value={false}>False</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </div>
            </div>

            <div className="business-info-container">
                <h3 className="section-title">Product Entry</h3>
                <div className="section">
                    <div className="inline-three-inputs-vendor">
                        <Form.Item label={(`Product Entry Type`)} name="product_entry_type">
                            <Select
                                placeholder={(`Product Entry Type`)}
                                value={initialVendor?.product_entry_info?.product_entry_type}
                                onChange={(e) => {
                                    setBusinessInfo("product_entry_info", "product_entry_type",  e);
                                }}>
                                    <Option value="API">API</Option>
                                    <Option value="XML">XML</Option>
                                    <Option value="Scraper">Scraper</Option>
                                    <Option value="Google Sheets">Google Sheets</Option>
                                    <Option value="Supplier Panel">Supplier Panel</Option>
                                    <Option value="Admin Panel">Admin Panel</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={(`Product Entry Tech`)} name="product_entry_tech">
                            <Select
                                placeholder={(`Product Entry Tech`)}
                                value={initialVendor?.product_entry_info?.product_entry_tech}
                                onChange={(e) => {
                                    setBusinessInfo("product_entry_info", "product_entry_tech",  e);
                                }}>
                                    <Option value="Ticimax">Ticimax</Option>
                                    <Option value="Nebim">Nebim</Option>
                                    <Option value="Faprika">Faprika</Option>
                                    <Option value="Others">Others</Option>
                            </Select>
                        </Form.Item>

                        <div className="checkbox-container">
                            <span className="checkbox-title">Auto-order:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("product_entry_info", "auto_order",  e.target.value);
                                }} 
                                value={typeof initialVendor?.product_entry_info?.auto_order !== "undefined" ? initialVendor?.product_entry_info?.auto_order : false}
                            >
                                <Radio.Button value={true}>True</Radio.Button>
                                <Radio.Button value={false}>False</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>

                    <div className="inline-inputs-five">
                        <Form.Item label={(`Final Sales Price `)} name="margin_included">
                            <Select
                                value={initialVendor?.margin_included}
                                onChange={(e) => {
                                    setBusinessInfo(false, "margin_included",  e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>

                        </Form.Item>

                        <Form.Item 
                            label={(`Category`)} 
                            name="category" 
                            rules={[
                                {
                                    required: isNew && !initialVendor?.category,
                                    message: 'Please select a category',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                value={categories.slice()?.filter((category) => category?._id === initialVendor?.category)[0]?.name}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => {
                                    setBusinessInfo(false, "category",  e.split('-')[0]);
                                }}>
                                { categories && categories
                                    .slice()
                                    .sort((a, b) => a.category_ranking - b.category_ranking)
                                    .map((category) => (
                                        <Option key={category?._id}  value={category?._id + '-' + category?.name}   onMouseEnter={() => handleMouseEnter(category)}>
                                            {category?.hierarchy[0]?.name && category?.hierarchy?.slice().sort((a, b) => a.category_ranking - b.category_ranking)[category?.hierarchy.length -1]?.name}  {category.name} {category.category_ranking}
                                        </Option>
                                    ))
                                }
                            </Select>
                            {showHoverText && <div className="hovered-category">{ hoverText}</div>}
                        </Form.Item>

                        <Form.Item label={(`Worker Updatable`)} name="should_update_in_worker">
                            <Select
                                value={initialVendor?.should_update_in_worker}
                                onChange={(e) => {
                                    setBusinessInfo(false, "should_update_in_worker",  e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label={(`Vendor Safe Stock Buffer`)}  name="safe_stock_buffer">
                            <Input
                                type = "number"
                                required
                                min={0}
                                value={initialVendor?.safe_stock_buffer}
                                placeholder="0"
                                onChange={(e) => {
                                    setBusinessInfo(false, "safe_stock_buffer",  Number(e.target.value));
                                }}>
                            </Input>
                        </Form.Item>
                        
                        <Form.Item label={(`Is KDV Included`)} name="is_kdv_included">
                            <Select
                                value={initialVendor?.is_kdv_included}
                                onChange={(e) => {
                                    setBusinessInfo(false, "is_kdv_included",  e);
                                }}>
                                    <Option value={true}>True</Option>
                                    <Option value={false}>False</Option>
                            </Select>

                        </Form.Item>
                    </div>
                </div>
            </div>

            <div className="business-info-container">
                <h3 className="section-title">Accounting</h3>
                <div className="section">
                    <div className="inline-inputs">
                        <div className="checkbox-container">
                            <span className="checkbox-title">Invoice type:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("accounting_info", "invoice_type",  e.target.value);
                                }} 
                                value={typeof initialVendor?.accounting_info?.invoice_type !== "undefined" ? initialVendor?.accounting_info?.invoice_type : false}
                            >
                                <Radio.Button value="E-Arşiv">E-Arşiv</Radio.Button>
                                <Radio.Button value="E-Fatura">E-Fatura</Radio.Button>
                            </Radio.Group>
                        </div> 

                        <div className="checkbox-container">
                            <span className="checkbox-title">Invoice paid:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("accounting_info", "invoice_paid",  e.target.value);
                                }} 
                                value={typeof initialVendor?.accounting_info?.invoice_paid !== "undefined" ? initialVendor?.accounting_info?.invoice_paid : false}
                            >
                                <Radio.Button value={true}>True</Radio.Button>
                                <Radio.Button value={false}>False</Radio.Button>
                            </Radio.Group>
                        </div> 
                    </div>

                    <div className="inline-inputs">
                        <div className="checkbox-container">
                            <span className="checkbox-title">Supplier invoice:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("accounting_info", "supplier_invoice",  e.target.value);
                                }} 
                                value={typeof initialVendor?.accounting_info?.supplier_invoice !== "undefined" ? initialVendor?.accounting_info?.supplier_invoice : false}
                            >
                                <Radio.Button value="Using">Using</Radio.Button>
                                <Radio.Button value="Not Using">Not Using</Radio.Button>
                            </Radio.Group>
                        </div> 

                        <div className="checkbox-container">
                            <span className="checkbox-title">Return invoice:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("accounting_info", "return_invoice",  e.target.value);
                                }} 
                                value={typeof initialVendor?.accounting_info?.return_invoice !== "undefined" ? initialVendor?.accounting_info?.return_invoice : false}
                            >
                                <Radio.Button value={true}>True</Radio.Button>
                                <Radio.Button value={false}>False</Radio.Button>
                            </Radio.Group>
                        </div> 
                        <div className="checkbox-container">
                            <span className="checkbox-title">Product Code on Invoice:</span>

                            <Radio.Group 
                                onChange={(e) => {
                                    setBusinessInfo("accounting_info", "supplier_product_code",  e.target.value);
                                }} 
                                value={typeof initialVendor?.accounting_info?.supplier_product_code !== "undefined" ? initialVendor?.accounting_info?.supplier_product_code : "Not Exists"}
                            >
                                <Radio.Button value="Exists">Exists</Radio.Button>
                                <Radio.Button value="Not Exists">Not Exists</Radio.Button>
                            </Radio.Group>
                        </div> 
                    </div>
                </div>
            </div>

            <div className="business-info-container">
                <h3 className="section-title">Payment</h3>
                <div className="section">
                    <div className="inline-inputs">
                        <Form.Item label={(`Billing Name`)}  name="billing_name">
                            <Input
                                value={initialVendor?.payment_info?.billing_name}
                                placeholder="Billing Name"
                                onChange={(e) => {
                                    setBusinessInfo("payment_info", "billing_name",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`Bank Info`)}  name="bank_info">
                            <Input
                                value={initialVendor?.payment_info?.bank_info}
                                placeholder="Bank Info"
                                onChange={(e) => {
                                    setBusinessInfo("payment_info", "bank_info",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>
                    </div>

                    <div className="inline-inputs">
                        <Form.Item label={(`First Name / Last Name`)}  name="full_name">
                            <Input
                                value={initialVendor?.payment_info?.full_name}
                                placeholder="First Name / Last Name"
                                onChange={(e) => {
                                    setBusinessInfo("payment_info", "full_name",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>

                        <Form.Item label={(`IBAN`)}  name="iban">
                            <Input
                                value={initialVendor?.payment_info?.iban}
                                placeholder="IBAN"
                                onChange={(e) => {
                                    setBusinessInfo("payment_info", "iban",  e.target.value);
                                }}>
                            </Input>
                        </Form.Item>
                    </div>
                </div>
            </div>

            <div className="business-info-container">
                <h3 className="section-title">People</h3>
                <div className="section">
                    <div>
                        { !addNewPeople && <Button type="secondary" onClick={() => setAddNewPeople(true)}>Add New People</Button> }

                        { addNewPeople && <div>
                            <div className="inline-inputs-four center-column">
                                <Form.Item label={(`Person Role`)} name="person_role">
                                    <Select
                                        placeholder={(`Person Role`)}
                                        value={draftPerson?.role}
                                        onChange={(e) => {
                                            setDraftPerson({ ...draftPerson, role: e });
                                        }}>
                                            <Option value="Owner">Owner</Option>
                                            <Option value="Responsible">Responsible</Option>
                                            <Option value="Sales">Sales</Option>
                                            <Option value="IT">IT</Option>
                                            <Option value="Warehouse">Warehouse</Option>
                                            <Option value="Accounting">Accounting</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label={(`Full Name`)}  name="person_full_name">
                                    <Input
                                        value={draftPerson?.full_name}
                                        placeholder="Full Name"
                                        onChange={(e) => {
                                            setDraftPerson({ ...draftPerson, full_name: e.target.value });
                                        }}>
                                    </Input>
                                </Form.Item>

                                <Form.Item label={(`Phone Number`)}  name="person_phone_number">
                                    <Input
                                        value={draftPerson?.phone_number}
                                        placeholder="Phone Number"
                                        onChange={(e) => {
                                            setDraftPerson({ ...draftPerson, phone_number: e.target.value });
                                        }}>
                                    </Input>
                                </Form.Item>

                                <div>
                                    <ButtonGroup>
                                        <Button type="primary" className="add-person-button" onClick={handleAddNewPerson}>Save New People</Button>
                                        <Button type="secondary" className="add-person-button" onClick={() => setAddNewPeople(false)}>Cancel</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div> }
                    </div>

                    <div className="seperator"></div>

                    <div className="people-list">
                        <h4>Saved People</h4>

                        { (initialVendor?.people_info || [])?.map(person => (
                            <div className="inline-inputs-four center-column">
                                <Form.Item label={(`Person Role`)}>
                                    <Select
                                        value={person?.role}
                                        disabled
                                    >
                                            <Option value="Owner">Owner</Option>
                                            <Option value="Responsible">Responsible</Option>
                                            <Option value="Sales">Sales</Option>
                                            <Option value="IT">IT</Option>
                                            <Option value="Warehouse">Warehouse</Option>
                                            <Option value="Accounting">Accounting</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label={(`Full Name`)}>
                                    <Input
                                        value={person?.full_name}
                                        disabled
                                    >
                                    </Input>
                                </Form.Item>

                                <Form.Item label={(`Phone Number`)}>
                                    <Input
                                        value={person?.phone_number}
                                        disabled    
                                    >
                                    </Input>
                                </Form.Item>

                                <div 
                                    className="person-trash"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Button onClick={() => handlePersonDelete(person?._id)} icon={<DeleteOutlined />} />
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>

            <div className="business-info-container">
                <h3 className="section-title">Sub Vendors</h3>
                <div className="section">
                    <div>
                        { !addNewSubVendor && <Button type="secondary" onClick={() => setAddNewSubVendor(true)}>Add New Sub Vendor</Button> }

                        { addNewSubVendor && <div>
                            <div className="inline-inputs-four center-column">
                                <Form.Item label={(`Name`)} name="sub_vendor_name">
                                    <Select
                                        placeholder={(`Name`)}
                                        value={draftSubVendor?.name}
                                        onChange={(e) => {
                                            setDraftSubVendor({ ...draftSubVendor, name: e });
                                        }}>
                                            {subVendors?.map((subVendor) => subVendor?.name)
                                                ?.slice()
                                                .sort((a, b) => a.localeCompare(b))
                                                .map((vendor, index) => (
                                                    <Option key={'vendor-select' + index} value={vendor}>
                                                        {vendor}
                                                    </Option>
                                            ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item label={(`Scraper Days`)}>
                                    <Input
                                        type= "number"
                                        min={0}
                                        step="1"
                                        value={draftSubVendor?.scraper_days}
                                        placeholder="0"
                                        onChange={(e) => {
                                            setDraftSubVendor({ ...draftSubVendor, scraper_days: Number(e.target.value) });
                                        }}>
                                    </Input>
                                </Form.Item>

                                <div>
                                    <ButtonGroup>
                                        <Button type="primary" className="add-person-button" onClick={handleAddNewSubVendor}>Save New Sub Vendor</Button>
                                        <Button type="secondary" className="add-person-button" onClick={() => setAddNewSubVendor(false)}>Cancel</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div> }
                    </div>

                    <div className="seperator"></div>

                    <div className="people-list">
                        <h4>Sub Vendors</h4>

                        { (initialVendor?.sub_vendors || [])?.map((sub_vendor, index) => (
                            <div className="inline-inputs-four center-column">
                                <Form.Item label={(`Name`)}>
                                    <Select
                                        placeholder={(`Name`)}
                                        value={sub_vendor?.name}
                                        onChange={(e) => {
                                            const updatedSubVendors = [...initialVendor?.sub_vendors];
                                            updatedSubVendors[index] = { ...sub_vendor, name: e };
                                            setInitialVendor({ ...initialVendor, sub_vendors: updatedSubVendors });
                                            setUpdatedVendor({ ...updatedVendor, sub_vendors: updatedSubVendors });
                                        }}>
                                            {subVendors?.map((subVendor) => subVendor?.name)
                                                ?.slice()
                                                .sort((a, b) => a.localeCompare(b))
                                                .map((vendor, index) => (
                                                    <Option key={'vendor-select' + vendor} value={vendor}>
                                                        {vendor}
                                                    </Option>
                                            ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item label={(`Scraper Days`)}>
                                    <Input
                                        type= "number"
                                        min={0}
                                        step="1"
                                        value={sub_vendor?.scraper_days}
                                        placeholder="0"
                                        onChange={(e) => {
                                            const updatedSubVendors = [...initialVendor?.sub_vendors];
                                            updatedSubVendors[index] = { ...sub_vendor, scraper_days: Number(e.target.value) };
                                            setInitialVendor({ ...initialVendor, sub_vendors: updatedSubVendors });
                                            setUpdatedVendor({ ...updatedVendor, sub_vendors: updatedSubVendors });
                                        }}>
                                    </Input>
                                </Form.Item>

                                <div 
                                    className="person-trash"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Button onClick={() => handleSubVendorDelete(sub_vendor?.name)} icon={<DeleteOutlined />} />
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EditBusinessInfo;